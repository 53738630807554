<template>
    <layout>
        <div>
            <!-- Info -->
            <div class="mb-2">
                <info-collapse-area>
                    <template v-slot:title>
                        <div class="w-100 d-flex flex-column flex-md-row justify-content-md-between">
                            <div class="mt-1 mt-md-0 mb-1 mb-md-0 fs-16">
                                {{ getObjectValue(application, 'name') }} {{ getObjectValue(application, 'surname') }}
                            </div>
                            <!--
                            <div class="mb-1 mb-md-0 mr-0 mr-md-4">
                                <template v-if="application.result">
                                    <b-badge
                                        :variant="(application.result == 'placed' ? 'success' : 'danger') + ' text-uppercase'">
                                        {{ resultStatusText }}
                                    </b-badge>
                                </template>
                                <template v-else>
                                    {{ applicationStatusMessage }}
                                </template>
                            </div>
                            -->
                        </div>
                    </template>
                    <template v-slot:detail>
                        {{ getObjectValue(application, 'name') }}
                        {{ getObjectValue(application, 'surname') }} - {{ applicationStatusMessage }}
                    </template>
                </info-collapse-area>
            </div>
            <div class="border rounded-sm pt-4 pl-4 pr-4">
                <b-tabs content-class="py-5 position-relative" class="line-tabs" fill>
                    <div class="tabs-bottom-line"></div>
                    <b-tab :active="tabIndex == 0" @click="tabIndex = 0">
                        <template #title>
                            {{ $t('personal_info') }}
                        </template>
                        <template>
                            <ValidationObserver ref="personalInfoFormValidate">
                                <h6>{{ $t('identity_information') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('national_id')">
                                                    <ValidationProvider name="national_id" rules="required" v-slot="{ errors }">
                                                        <b-form-input v-model="formData.national_id"
                                                                      :state="errors[0] ? false : null" :disabled="true" />
                                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                    </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('name')">
                                                <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.name"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('surname')">
                                                <ValidationProvider name="surname" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.surname"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="birthdate" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('birthdate')">
                                                    <select-date v-model="formData.birthdate" :validationError="errors[0]" :disabled="true" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('birthplace')">
                                                <ValidationProvider name="birthplace" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.birthplace"
                                                                  :state="errors[0] ? false : null" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="gender" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('gender')">
                                                    <gender-selectbox v-model="formData.gender"
                                                                      :validateError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="nationality_code" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('nationality')">
                                                    <nationality-selectbox v-model="formData.nationality_code"
                                                                      :validateError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('father_name')">
                                                <ValidationProvider name="father_name" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.father_name"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('mother_name')">
                                                <ValidationProvider name="mother_name" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.mother_name"
                                                                  :state="errors[0] ? false : null" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>

                                <h6>{{ $t('contact_information') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('email')">
                                                <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.email"
                                                                  :state="errors[0] ? false : null" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('gsm_no')">
                                                <ValidationProvider name="mobile_tel" rules="required" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.mobile_tel"
                                                                  v-mask="'### ### ## ##'"
                                                                  :state="errors[0] ? false : null" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('alternative_tel')">
                                                <ValidationProvider name="alternative_mobile_tel" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.alternative_mobile_tel"
                                                                  v-mask="'### ### ## ##'"
                                                                  :state="errors[0] ? false : null" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>

                                <b-row>
                                    <b-col cols="12" md="6">
                                        <h6>{{ $t('residence_address') }}</h6>
                                        <div class="border p-4 rounded-sm mb-3">
                                            <b-row>
                                                <b-col cols="12" md="6" lg="6">
                                                    <ValidationProvider name="city" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('city')">
                                                            <city-selectbox v-model="formData.city"
                                                                              :validateError="errors[0]" />
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="6" lg="6">
                                                    <ValidationProvider name="district" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('district')">
                                                            <district-outline-selectbox v-model="formData.district"
                                                                            :city_id="formData.city"
                                                                            :validateError="errors[0]" />
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="12" lg="12">
                                                    <ValidationProvider name="address" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('address')">
                                                            <b-form-textarea v-model="formData.address" rows="3"
                                                                             :state="errors[0] ? false : null" />
                                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]" />
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="6" lg="6">
                                                    <b-form-group :label="$t('postal_code')">
                                                        <ValidationProvider name="postal_code" rules="" v-slot="{ errors }">
                                                            <b-form-input v-model="formData.postal_code"
                                                                          :state="errors[0] ? false : null" />
                                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                        </ValidationProvider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <h6>{{ $t('communication_address') }}</h6>
                                        <div class="border p-4 rounded-sm mb-3">
                                            <b-row>
                                                <b-col cols="12" md="6" lg="6">
                                                    <ValidationProvider name="contact_city" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('city')">
                                                            <city-selectbox v-model="formData.contact_city"
                                                                            :validateError="errors[0]" />
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="6" lg="6">
                                                    <ValidationProvider name="contact_district" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('district')">
                                                            <district-outline-selectbox v-model="formData.contact_district"
                                                                                        :city_id="formData.contact_city"
                                                                                        :validateError="errors[0]" />
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="12" lg="12">
                                                    <ValidationProvider name="contact_address" rules="" v-slot="{ errors }">
                                                        <b-form-group :label="$t('address')">
                                                            <b-form-textarea v-model="formData.contact_address" rows="3"
                                                                             :state="errors[0] ? false : null" />
                                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]" />
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col cols="12" md="6" lg="6">
                                                    <b-form-group :label="$t('postal_code')">
                                                        <ValidationProvider name="contact_postal_code" rules="" v-slot="{ errors }">
                                                            <b-form-input v-model="formData.contact_postal_code"
                                                                          :state="errors[0] ? false : null" />
                                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                        </ValidationProvider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-alert variant="info" show>{{ $t('fashion_design_contact_info') }}</b-alert>
                                    </b-col>
                                </b-row>
                                <div class="d-flex justify-content-center mt-3">
                                    <processing-button :processing="formLoading" variant="primary"
                                                       :label="$t('save').toUpper()" @click="save" />
                                </div>
                            </ValidationObserver>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 1" @click="tabIndex = 1">
                        <template #title>
                            {{ $t('education_info') }}
                        </template>
                        <template>
                            <ValidationObserver ref="educationInfoFormValidate">
                                <h6>{{ $t('high_school_info') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('graduate_high_school_name')">
                                                <ValidationProvider name="school_name" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.school_name"
                                                                  :state="errors[0] ? false : null" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('high_school_diploma_score')">
                                                <ValidationProvider name="school_diploma_point" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.school_diploma_point"
                                                                  type="number"
                                                                  :state="errors[0] ? false : null" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="school_major_type" rules="" v-slot="{ errors }">
                                                <b-form-group :label="$t('high_school_major_type')">
                                                    <multi-selectbox v-model="formData.school_major_type"
                                                                     :options="schoolMajorTypeOptions"
                                                                     :multiple="false"
                                                                     :validateError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('graduate_high_school_code')">
                                                <ValidationProvider name="school_code" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.school_code"
                                                                  :state="errors[0] ? false : null" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <ValidationProvider name="school_graduate_date" rules="" v-slot="{ errors }">
                                                <b-form-group :label="$t('high_school_graduate_date')">
                                                    <select-date v-model="formData.school_graduate_date" :validationError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </div>
                                <h6>{{ $t('exam_info') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('tyt_year')">
                                                <ValidationProvider name="exam_year" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.exam_year"
                                                                  type="number"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('tyt_score')">
                                                <ValidationProvider name="exam_point" rules="" v-slot="{ errors }">
                                                    <b-form-input v-model="formData.tyt_point"
                                                                  type="number"
                                                                  :state="errors[0] ? false : null"
                                                                  :disabled="true"/>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                    <b-form-text id="input-live-help">{{ $t('tyt_info') }}</b-form-text>
                                                </ValidationProvider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                                <b-row>
                                    <b-col cols="12">
                                        <b-alert variant="info" show>{{ $t('fashion_design_contact_info') }}</b-alert>
                                    </b-col>
                                </b-row>
                                <div class="d-flex justify-content-center mt-3">
                                    <processing-button :processing="formLoading" variant="primary"
                                                       :label="$t('save').toUpper()" @click="saveEducationInfo" />
                                </div>
                            </ValidationObserver>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 2" @click="tabIndex = 2">
                        <template #title>
                            {{ $t('application_information') }}
                        </template>
                        <template>
                            <ValidationObserver ref="applicationInfoFormValidate">
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="4">
                                            <ValidationProvider name="program_code" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('program_select')">
                                                    <multi-selectbox v-model="formData.program_code"
                                                                     :options="programOptions"
                                                                     :disabled="true"
                                                                     :multiple="false"
                                                                     :validateError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="school_major_type"
                                                                v-show="formData.program_code == '11022101'"
                                                                :rules="formData.program_code == '11022101' ? 'required' : ''"
                                                                v-slot="{ errors }">
                                                    <b-form-group :label="$t('branch')">
                                                        <multi-selectbox v-model="formData.program_detail"
                                                                         :options="branchOptions"
                                                                         :validateError="errors[0]" />
                                                    </b-form-group>
                                                </ValidationProvider>
                                            <ValidationProvider name="interview_type" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('interview_preference')">
                                                    <parameter-selectbox v-model="formData.interview_type"
                                                                         code="fashion_design_application_interview_preference"
                                                                         :public="true"
                                                                       :multiple="false"
                                                                       :validateError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="8">
                                            <ValidationProvider name="letter_of_intent" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('letter_of_intent')">
                                                    <b-form-textarea v-model="formData.letter_of_intent" rows="7"
                                                                     placeholder="Bu alana kendinizi tanıtan, bölümü seçmedeki amaçlarınızı belirten ve şimdiye kadar yaptığınız çalışmaları içeren bir niyet mektubu yazınız."
                                                                     :state="errors[0] ? false : null" />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="file" rules="" v-slot="{errors}">
                                                <b-input-group class="mb-0">
                                                    <b-form-file v-model="formData.file"
                                                                 :placeholder="$t('upload_file')"
                                                                 :state="errors[0] ? false : null"
                                                                 ref="fileInput"
                                                                 :disabled="true"
                                                    />
                                                    <b-input-group-append>
                                                        <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click()">{{ $t('browse') }}</b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                <div class="alert alert-info mt-2">{{$t('fashion_design_filsize_alert')}}</div>
                                                <b-button variant="outline-primary" v-if="formData.document" @click="downloadAppFile">
                                                    <i class="ri-download-2-line font-size-16 mr-2"></i> {{$t('file_download')}}
                                                </b-button>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12" class="mt-4">
                                            <div @click="openApproveModal('App')">
                                                <ValidationProvider name="approveApp" rules="required" v-slot="{errors}">
                                                    <b-form-checkbox
                                                        :id="'checkbox-app'"
                                                        :name="'checkbox-app'"
                                                        :value="true"
                                                        :unchecked-value="false"
                                                        :state="approveAppValidate ? false : null"
                                                        @change="approveApp = false"
                                                        v-model="approveApp">
                                                        {{ $t('fashion_design_app_approve') }}
                                                    </b-form-checkbox>
                                                    <b-form-invalid-feedback v-if="approveAppValidate">{{ $t('this_field_is_required') }}</b-form-invalid-feedback>
                                                </ValidationProvider>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" class="mt-4">
                                            <div @click="openApproveModal('Multi')">
                                                <ValidationProvider name="approveMultimedia" rules="required" v-slot="{errors}">
                                                    <b-form-checkbox
                                                        :id="'checkbox-multi'"
                                                        :name="'checkbox-multi'"
                                                        :value="true"
                                                        :state="approveMultimediaValidate ? false : null"
                                                        :unchecked-value="false"
                                                        @change="approveMultimedia = false"
                                                        v-model="approveMultimedia">
                                                        {{ $t('fashion_design_app_approve_multimedia') }}
                                                    </b-form-checkbox>
                                                    <b-form-invalid-feedback v-if="approveMultimediaValidate">{{ $t('this_field_is_required') }}</b-form-invalid-feedback>
                                                </ValidationProvider>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                                <b-row>
                                    <b-col cols="12">
                                        <b-alert variant="info" show>{{ $t('fashion_design_contact_info') }}</b-alert>
                                    </b-col>
                                </b-row>
                                <div class="d-flex justify-content-center mt-3" v-if="application.status == 'waiting_program'">
                                    <processing-button :processing="formLoading" variant="primary"
                                                       :label="$t('send_application').toUpper()" @click="saveApplicationInfo" />
                                </div>
                                <div class="d-flex justify-content-center mt-3" v-if="application.status == 'waiting'">
                                    <processing-button :processing="formLoading" variant="primary"
                                                       :label="$t('save').toUpper()" @click="updateApplicationInfo" />
                                </div>
                            </ValidationObserver>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 3" @click="tabIndex = 3">
                        <template #title>
                            {{ $t('special_talent_exam') }}
                        </template>
                        <template>
                            <h6>{{ $t('exam_info') }}</h6>
                            <div class="border p-4 rounded-sm mb-3">
                                <b-row>
                                    <b-col cols="6" md="6" class="text-right text-black-50 font-size-16">{{ $t('exam_place') }}</b-col>
                                    <b-col cols="6" md="6" class="text-size-16">{{application.exam_location != null ? application.exam_location : '--- ---'}}</b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6" md="6" class="text-right text-black-50 font-size-16">{{ $t('exam_datetime') }}</b-col>
                                    <b-col cols="6" md="6" class="text-size-16">{{application.exam_date != null ? application.exam_date : '--.--.---- --:--'}}</b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" class="mt-4">
                                        <b-alert variant="info" show v-if="application.status == 'waiting'">
                                            Başvurunuz alınmıştır.
                                        </b-alert>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-alert variant="info" show>{{ $t('fashion_design_contact_info') }}</b-alert>
                                    </b-col>
                                </b-row>
                            </div>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 4" @click="tabIndex = 4">
                        <template #title>
                            {{ $t('application_status') }}
                        </template>
                        <template>
                            <h6>{{ $t('estimate_status') }}</h6>
                            <div class="border p-4 rounded-sm mb-3">
                                <b-row>
                                    <b-col cols="12">
                                        <b-table :empty-filtered-text="$t('no_result')"
                                                 :empty-text="$t('no_result')"
                                                 bordered
                                                 responsive
                                                 :items="application.messages"
                                                 :fields="estimateFields"
                                                 show-empty
                                                 class="mb-4 table-dropdown no-scrollbar border rounded" />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-alert variant="info" show>{{ $t('fashion_design_contact_info') }}</b-alert>
                                    </b-col>
                                </b-row>
                            </div>
                        </template>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <CommonModal ref="approveAppModal" size="md">
            <template v-slot:CommonModalContent>
                {{approveModalContent}}
                <div class="d-flex justify-content-center mt-4">
                    <b-button variant="primary" @click="approveAction">{{$t('i_approve')}}</b-button>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="progressModal" size="lg" :closeBtn="false" :onHideOnlyX="true">
            <template v-slot:CommonModalContent>
                <b-row>
                    <b-col cols="12">
                        <b-progress max="100" :value="progressValue" show-progress class="mb-3"></b-progress>
                    </b-col>
                    <b-col cols="12">
                        <b-alert show variant="info">{{$t('progress_bar_text',{min: progressTime})}}</b-alert>
                    </b-col>
                </b-row>
            </template>
        </CommonModal>
    </layout>
</template>

<script>
// Layouts
import Layout from '../layout/Layout'

// Component
import GenderSelectbox from '@/components/interactive-fields/GenderSelectbox'
import InfoCollapseArea from '@/components/elements/InfoCollapseArea'
import NationalitySelectbox from '@/components/interactive-fields/NationalitySelectbox'
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'
import ProcessingButton from '@/components/elements/ProcessingButton'
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
import SelectDate from '@/components/interactive-fields/SelectDate'
import CitySelectbox from '@/components/interactive-fields/CitySelectbox'
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox"
import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox'
import YearsSelectbox from "@/components/interactive-fields/YearsSelectbox"

// Services
import FashionDesignService from "@/services/FashionDesignService";
import ConstantService from "@/services/ConstantService";

// Pages

// Other
import CommonModal from '@/components/elements/CommonModal';
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import isset from 'isset-php'
import Branchs from "@/modules/fashionDesignApplication/data/Branchs"
import qs from "qs";

export default {

    components: {
        Layout,
        GenderSelectbox,
        InfoCollapseArea,
        NationalitySelectbox,
        ProcessingButton,
        ProgramSelectbox,
        SelectDate,
        CommonModal,
        CitySelectbox,
        DistrictOutlineSelectbox,
        MultiSelectbox,
        YearsSelectbox,
        ParameterSelectbox,
        ValidationObserver,
        ValidationProvider
    },
    metaInfo() {
        return {
            title: this.$t("fashion_design")+' '+this.$t('application_system'),
        };
    },
    computed: {
        applicationStatus() {
            let data = this.$store.getters['fashionDesignApplication/getData'];
            return isset(() => data.undergraduate_transfer.status) ? data.undergraduate_transfer.status : null
        },
        applicationStatusMessage() {
            if (this.application.status == 'waiting_program') {
                return this.$t('application_information_is_awaited');
            }
            else if (this.application.status == 'waiting_preference') {
                return this.$t('preference_information_is_awaited');
            }
            else if (this.application.status == 'waiting_documents') {
                return this.$t('required_documents_is_awaited');
            }
            else if (this.application.status == 'waiting_approve') {
                return this.$t('required_documents_approval_is_awaited');
            }
            else if (this.application.status == 'approved') {
                return this.$t('your_application_has_been_accepted');
            }
            else if (this.application.status == 'declined') {
                return this.$t('your_application_has_been_rejected');
            }
            else if (this.application.status == 'publish') {
                return this.$t('your_application_publish');
            }
            else if (this.application.status) {
                return this.$t('your_application_has_been_evaluated');
            }
            else {
                return '';
            }
        },

        resultStatusText(){
            let result = null
            if(this.application.result == 'waiting') {
                result = this.$t('waiting')
            } else if(this.application.result == 'placed') {
                result = this.$t('placed')
            } else {
                result = this.application.result
            }

            return result
        },
    },
    watch: {

    },
    data() {
        return {
            // Tab
            tabIndex: 0,

            // Pin
            pin: null,

            // Data
            application: {},
            // Form
            formLoading: false,
            formData: {},

            schoolMajorTypeOptions: [
                { value: null, text: '' },
                { value: this.$t('high_school_major_type_1'), text: this.$t('high_school_major_type_1') },
                { value: this.$t('high_school_major_type_2'), text: this.$t('high_school_major_type_2') },
                { value: this.$t('high_school_major_type_3'), text: this.$t('high_school_major_type_3') },
                { value: this.$t('high_school_major_type_4'), text: this.$t('high_school_major_type_4') },
            ],

            approveModalContent: null,
            approveMode: null,
            approveApp: false,
            approveMultimedia: false,
            progressValue: 0,
            progressTime: null,
            estimateFields: [
                {
                    key: 'message',
                    label: this.$t('message'),
                },
                {
                    key: 'date',
                    label: this.$t('date'),
                },
            ],
            approveMultimediaValidate: false,
            approveAppValidate: false,
            branchOptions: null,
            programOptions: []
        }
    },

    created() {
        // Branch Options
        this.branchOptions = Branchs()
    },

    mounted() {
        this.$store.dispatch('fashionDesignApplication/initData');
        this.$store.dispatch('fashionDesignApplication/isLogin');
        let data = this.$store.getters['fashionDesignApplication/getData'];
        if (data) {
            this.application = data
            this.application.city = parseInt(this.application.city)
            this.application.contact_city = parseInt(this.application.contact_city)
        }
        // Pin
        this.pin = this.$store.getters['fashionDesignApplication/getPin'];

        this.formData = null
        this.setFormData();

        this.goToTab();
    },

    methods: {
        openApproveModal(mode){
            this.approveMode = mode
            mode == 'App' ? this.approveApp = false : this.approveMultimedia = false
            this.approveModalContent = mode == 'App' ? this.$t('fashion_design_app_approve_text') : this.$t('fashion_design_app_aprove_multimedia_text')
            this.$refs.approveAppModal.$refs.commonModal.show()
        },

        approveAction(){
            this.approveMode == 'App' ? this.approveApp = true : this.approveMultimedia = true
            this.$refs.approveAppModal.$refs.commonModal.hide()
        },

        goToTab() {
            if (this.applicationStatus) {
                if (this.applicationStatus == 'waiting_program') {
                    this.tabIndex = 0;
                }
                else if (this.applicationStatus == 'waiting_preference') {
                    this.tabIndex = 1;
                }
                else if (this.applicationStatus == 'waiting_documents') {
                    this.tabIndex = 2;
                }
                else if (this.applicationStatus == 'waiting_approve') {
                    this.tabIndex = 3;
                }
            }
        },

        // Set Form Data
        setFormData() {
            this.formData = this.application
            if(this.formData.exam_date != null) {
                this.formData.exam_date = this.formData.exam_date.substr(0,16)
            }
            this.getProgramOptions()
        },

        // Save
        async save() {
            const isValid = await this.$refs.personalInfoFormValidate.validate();
            if (isValid) {
                this.formData.mobile_tel = this.formData.mobile_tel.replace(/[^\d]/g, '')
                this.formData.alternative_mobile_tel = this.formData.alternative_mobile_tel ? this.formData.alternative_mobile_tel.replace(/[^\d]/g, '') : null

                let formData = {
                    pin: this.pin,
                    ...this.formData
                }

                this.formLoading = true
                FashionDesignService.updatePersonalInfo(formData).then(response => {
                    let data = response.data.data;
                    this.application = data
                    this.setFormData();
                    this.$store.commit('fashionDesignApplication/setData', data)

                    this.$toast.success(this.$t('successfully_saved'));
                    this.tabIndex = 1;
                }).catch(e => {
                    this.showErrors(e, this.$refs.personalInfoFormValidate);
                }).finally(() => {
                    this.formLoading = false
                })

            }
        },

        // Save Education Info
        async saveEducationInfo() {
            const isValid = await this.$refs.educationInfoFormValidate.validate();
            if (isValid) {
                let formData = {
                    national_id: this.formData.national_id,
                    pin: this.pin,
                    school_name: this.formData.school_name,
                    school_code: this.formData.school_code,
                    school_diploma_point: this.formData.school_diploma_point,
                    school_graduate_date: this.formData.school_graduate_date,
                    school_major_type: this.formData.school_major_type,
                    exam_year: this.formData.exam_year,
                    exam_point: this.formData.exam_point
                }

                this.formLoading = true
                FashionDesignService.updateEducationInfo(formData).then(response => {
                    let data = response.data.data;
                    this.application = data
                    this.setFormData();
                    this.$store.commit('fashionDesignApplication/setData', data)

                    this.$toast.success(this.$t('successfully_saved'));
                    this.tabIndex = 2;
                }).catch(e => {
                    this.showErrors(e, this.$refs.educationInfoFormValidate);
                }).finally(() => {
                    this.formLoading = false
                })

            }
        },

        async saveApplicationInfo(){
            const isValid = await this.$refs.applicationInfoFormValidate.validate();
            if (isValid && this.approveApp && this.approveMultimedia) {
                this.formLoading = true

                let formData = new FormData()
                formData.append('national_id',this.formData.national_id)
                formData.append('pin',this.pin)
                formData.append('program_code',this.formData.program_code)
                formData.append('interview_type',this.formData.interview_type)
                formData.append('letter_of_intent',this.formData.letter_of_intent)
                if(this.formData.file){
                    this.showProgress(this.formData.file.size)
                    formData.append('file',this.formData.file)
                }
                if(this.formData.program_code == '11022101'){
                    formData.append('program_detail',this.formData.program_detail.toString())
                }
                
                FashionDesignService.updateApplicationInfo(formData).then(response => {
                    let data = response.data.data;
                    this.application = data
                    this.setFormData();
                    this.$store.commit('fashionDesignApplication/setData', data)

                    this.$toast.success(this.$t('successfully_saved'));
                    this.tabIndex = 3;
                    this.approveMultimediaValidate = false
                    this.approveAppValidate = false

                    this.$swal.fire({
                        title: this.$t('application_successfully_sent'),
                        text: ' ',
                        showCancelButton: false,
                        confirmButtonText: this.$t('ok'),
                    })
                }).catch(e => {
                    this.showErrors(e, this.$refs.applicationInfoFormValidate);
                }).finally(() => {
                    this.formLoading = false
                })
            } else {
                if(this.approveMultimedia == false) {
                    this.approveMultimediaValidate = true
                }
                if(this.approveApp == false) {
                    this.approveAppValidate = true
                }

            }
        },

        async updateApplicationInfo(){
            const isValid = await this.$refs.applicationInfoFormValidate.validate();
            if (isValid && this.approveApp && this.approveMultimedia) {
                this.formLoading = true

                let formData = new FormData()
                formData.append('national_id',this.formData.national_id)
                formData.append('pin',this.pin)
                formData.append('program_code',this.formData.program_code)
                formData.append('interview_type',this.formData.interview_type)
                formData.append('letter_of_intent',this.formData.letter_of_intent)
                if(this.formData.file){
                    this.showProgress(this.formData.file.size)
                    formData.append('file',this.formData.file)
                }
                if(this.formData.program_code == '11022101'){
                    formData.append('program_detail',this.formData.program_detail.toString())
                }

                FashionDesignService.updateApplicationInfo(formData).then(response => {
                    let data = response.data.data;
                    this.application = data
                    this.setFormData();
                    this.$store.commit('fashionDesignApplication/setData', data)

                    this.$toast.success(this.$t('successfully_saved'));
                    this.tabIndex = 3;
                    this.approveMultimediaValidate = false
                    this.approveAppValidate = false

                    this.$swal.fire({
                        title: this.$t('application_update_success'),
                        text: ' ',
                        showCancelButton: false,
                        confirmButtonText: this.$t('ok'),
                    })
                }).catch(e => {
                    this.showErrors(e, this.$refs.applicationInfoFormValidate);
                }).finally(() => {
                    this.formLoading = false
                })
            } else {
                if(this.approveMultimedia == false) {
                    this.approveMultimediaValidate = true
                }
                if(this.approveApp == false) {
                    this.approveAppValidate = true
                }

            }
        },

        showProgress(filesize){
            let uploadSpeed = 3000000 //bayt/s
            let maxValueInSeconds = Math.ceil(filesize/uploadSpeed)
            let onePercentPerSeconds = (maxValueInSeconds/100)*1000

            this.$refs.progressModal.$refs.commonModal.show()
            setInterval(() => {
                if(this.progressValue < 100){
                    this.progressValue += 1
                }
                if(this.formLoading == false){
                    this.$refs.progressModal.$refs.commonModal.hide()
                }

            }, onePercentPerSeconds)
        },

        getProgramOptions(){
            const config = {
                params: {
                    'key': 'fashion_design_application.program_code'
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }
            ConstantService.getValue(config).then(response => {
                let parsed = JSON.parse(response.data.data)
                this.programOptions = parsed
                this.formData.program_code = parsed[0].value
            }).catch(e => {
                this.showErrors(e, null)
            })
        },

        downloadAppFile(){
            let formData = {
                national_id: this.formData.national_id,
                pin: this.pin
            }
            FashionDesignService.downloadFileApplicationSide(formData).then(response => {
                this._downloadFile(response, response.headers.file_name)
            }).catch(e => {
                this.showErrors(e, null, true)
            })
        }

    }
}
</script>
